<template>
	<div class="bg-light">
		<div class="bg">
			<img src="@imgs/lxwmbg.png" width="220px" height="93px" alt="" class="cpzx" />
		</div>
		<div class="container">
			<div class="our">
				<p style="width: 287px; margin: 0 auto">
					公司总部：云南省昆明市五华区高新区昌源中路75号同丰中心3楼
				</p>
				<p>电话：0871-68189267</p>
				<!-- <p>邮箱：heyy@hezongyy.com</p> -->
			</div>
			<div class="our-con">
				<div class="contact-our">
					<section class="area">地址：昆明市五华区经典双城a5栋2区2号商铺</section>
					<section>
						<img src="../assets/kxyd.png" width="100%" alt="" class="img" />
					</section>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: "conract",
	};
</script>
<style scoped>
	.our-con {
		padding-bottom: 30px;
	}

	.banner {
		padding: 42px 0;
		background-image: url("../assets/bg.png");
		background-repeat: no-repeat;
		background-size: cover;
	}

	.container {
		margin: auto;
		padding: 10px 15px;
	}

	.text-pos {
		text-align: left;
		color: brown !important;
	}

	.first {
		animation: myfirst 1.5s linear;
	}

	.senced {
		animation: mysenced 1.5s linear;
	}

	.text-nav {
		margin-bottom: 14px;
		text-align: right;
		font-size: 14px;
	}

	.our {
		margin-top: 38px;
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #666666;
	}

	.contact-our {
		box-shadow: rgb(102 102 102) 0px 0px 10px;
		/* height: 500px; */
		padding: 21px;
		box-sizing: border-box;
		margin-bottom: 21px;
	}

	.area {
		font-size: 24px;
		padding: 12px 0;
	}

	.img {
		animation: img 1.5s linear;
	}

	@keyframes myfirst {
		0% {
			transform: translateX(-50px);
			opacity: 0;
		}

		80% {
			opacity: 0.5;
		}

		100% {
			transform: translateX(0px);
		}
	}

	@keyframes mysenced {
		0% {
			transform: translateX(50px);
			opacity: 0;
		}

		80% {
			opacity: 0.5;
		}

		100% {
			transform: translateX(0px);
			opacity: 1;
		}
	}

	@keyframes img {
		0% {
			transform: scale(0) rotate(-360deg);
			opacity: 0;
		}

		80% {
			transform: scale(0.5);
			opacity: 0.8;
		}

		100% {
			transform: scale(1) rotate(0);
			opacity: 1;
		}
	}
</style>
